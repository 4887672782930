<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Content from '@/services/Content'
    import Swal from "sweetalert2";


    export default {
        components: { Layout, PageHeader},
        page: {
            title: "News Feed",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
      watch: {
        selectedLang(newLang) {
          this.getNews(newLang); // Call API with the selected language
        }
      },
        data() {
            return {
                title: "News Feed",
                items: [
                    {
                        text: "News",
                    },
                    {
                        text: "News Feed",
                        active: true,
                        href: "/news/feed",
                    },
                ],
                showRefresh:true,
                showAlert: false,
                isBusyButton: false,
                isBusy: false,
                error: null,
                showModal:false,
                modalData:{},
                link_id:null,
                newsLanguage:'',
                selectedLang:'',
                tableData: [],
                totalRows: 0,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "pdate_formatted",
                sortDesc: true,
                fields: [
                    {
                        key: "title",
                        label: "Title",
                        sortable: true,
                    },
                    {
                        key: "link",
                        label: "Link",
                        sortable: false,
                    },
                    {
                        key: "image",
                        label: "Image",
                        sortable: false,
                    },
                    {
                        key: "pdate_formatted",
                        label: "Published Date",
                        sortable: true,
                    },
                    "action",
                ],
                 "refreshNewsBtn":false
            };
        },
        async created() {
            await this.getNews()
            await this.getNewsLanguage()
            this.refreshNewsBtn = await this.checkObjectAccessCall('button.news.refresh');
        },
      methods: {
            async getNews(language){
                try {
                  const params = '1000';
                    this.toggleBusy();
                    const response = await Content.getNews(params, language)
                    this.tableData = response.data.news
                    this.totalRows = this.tableData.length
                    this.toggleBusy();
                } catch (error) {
                    this.toggleBusy();
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData = []
                    this.totalRows = 0;
                }
            },
          async getNewsLanguage(){
            try {
              this.toggleBusy();
              const response = await Content.getNewsLanguage()
              this.newsLanguage = response.data
              this.toggleBusy();
            } catch (error) {
              this.toggleBusy();
              this.error = error.response.data.error ? error.response.data.error : "";
              this.tableData = []
              this.totalRows = 0;
            }
          },

            removeNews(id){
                Swal.fire({
                    title: "Remove news article?",
                    text: "This news article will be removed!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Remove!",
                }).then((result) => {
                    if (result.value) {
                        try {
                            Content.removeNews(id)
                                .then((response) => {
                                    const res = response.data ? response.data : false;
                                    const error = response.data.error ? response.data.error : 'Failed';
                                    if(res){
                                        this.getNews()
                                        Swal.fire("Remove news", "News article is successfully removed!", "success");
                                    }else{
                                        Swal.fire("Fail!", error, "warning");
                                    }
                                })
                        }catch(error){
                            this.error = error.response.data.error ? error.response.data.error : "";
                        }
                    }
                });
            },

     async refreshNews() {
  try {
    this.isBusyButton = true;
                    const response = await Content.fireEvent({event_type: 'refresh_news'});
                    if(response.data.data == 'refreshed') {
                         this.showRefresh = false;
                         this.showAlert = true;
                           setTimeout(() => {
                               this.showAlert = false;
                               this.showRefresh = true;
                               this.getNews();
                             }, 10000); // 10 seconds

                    }
                     this.isBusyButton = false;

                } catch (error) {
                    this.isBusyButton = false;
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData = []
                    this.totalRows = 0;
                }
            },

            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },

        },
        middleware: "authentication",
    };
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                       <div class="row mb-0">
                          <div class="col-12">
                         <!-- When the Refresh News button is clicked, an event is triggered. News may not be available immediately. Timeout set before refreshing the table -->
                         <div v-if="showAlert" class="alert alert-warning" role="alert">
                                  Refresh initiated! The pulled news will be available shortly.
                               </div>
                            </div>
                          <div  v-if="newsLanguage && newsLanguage.length > 0" class="col-4 d-flex justify-content-start align-items-center">
                            <select class="form-select w-25" v-model="selectedLang" id="selectLang">
                              <option value="" disabled selected>Language</option>
                              <option v-for="lang in newsLanguage" :key="lang" :value="lang">{{ lang.toUpperCase() }}</option>
                            </select>
                          </div>
                            <div v-if="showRefresh" class="col-8 text-end">
                                <a v-if="refreshNewsBtn" href="javascript:void(0);" :disabled=isBusyButton class="btn btn-success waves-effect waves-light text-end" @click="refreshNews()">
                                    <span v-if="!isBusyButton"><i class="mdi mdi-refresh me-2"></i>Refresh News</span>
                                    <span v-else>Loading...</span>
                                </a>
                            </div>
                        </div>
                        <h4 class="card-title">Total News: {{totalRows}}</h4>
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-end"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :busy="isBusy"
                                    :items="tableData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    show-empty
                                    empty-text="No Data Found"
                                    @filtered="onFiltered"
                            >

                                <template v-slot:cell(link)="data">
                                    <a :href="data.item.link" target="_blank">{{data.item.link.slice(0, 80)}}...</a>
                                </template>

                                <template v-slot:cell(image)="data">
                                    <div class="flex-shrink-0">
                                        <img class="img-fluid table-images" :src="data.item.image">
                                    </div>
                                </template>

                                <template v-slot:cell(action)="data">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a href="javascript:void(0);" class="px-2 text-danger" v-b-tooltip.hover title="Remove News" @click="removeNews(data.item.source_id)">
                                                <i class="uil uil-trash-alt font-size-18"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </template>

                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Layout>
</template>